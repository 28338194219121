import BootstrapTable from "react-bootstrap-table-next"
import TableLoader from "../../../../_metronic/helpers/components/TableLoader"
import { DayJS, StatusBadges, errorToast } from "../../../../_metronic/helpers/Utils"
import { useEffect, useState } from "react"
import { getJobCredits, getJobHistory } from "../../../redux/Functions/JobsFunctions"
import {
  HistoryStatusCustom,
  PageSizeCustom,
  PaginationCustom,
} from "../../../../_metronic/helpers/JobHistoryFilters"
import { jobHistoryStatus } from "../../../../_metronic/common/constants"
import { Link, useNavigate } from "react-router-dom"
import { FaEye } from "react-icons/fa"
import LoaderSpinner from "../../../../_metronic/helpers/components/LoaderSpinner"

export default function JobView_Job_History() {
  const job_id: any = new URLSearchParams(window.location.search).get("job_id")
  const navigate = useNavigate()
  const [historyData, setHistoryData] = useState([])
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [historyStatus, setHistoryStatus] = useState<string>("")
  const [skip, setSkip] = useState(0)
  const [creditLoading, setCreditLoading] = useState(false)
  const [credits, setCredits] = useState<any>({})

  const columns = [
    {
      dataField: "no",
      text: "No",
      formatter: (cell: any, row: any, index: number) => {
        return (
          <>
            <div className='d-flex align-items-center'>{index + 1 + skip}</div>
          </>
        )
      },
    },
    {
      dataField: "id",
      text: "ID",
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>{cell || "-"}</div>
          </>
        )
      },
    },
    {
      dataField: "startTime",
      text: "Start Time",
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center text-nowrap'>
              {DayJS(cell).format("DD/MM/YYYY hh:mm:ss A")}
            </div>
          </>
        )
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <StatusBadges type={cell ? jobHistoryStatus[cell] : null} />
            </div>
          </>
        )
      },
    },
    {
      dataField: "logs",
      text: "Result",
      formatter: (cell: any, row: any) => {
        let message = ""

        if (row.status === "failed") {
          message = cell?.find((v: any) => v.type === "error")?.message
        } else if (row.status === "success") {
          message = cell?.find((v: any) => v.type === "success")?.message
        } else {
          message = cell[cell.length - 1]?.message
        }

        return (
          <>
            <div className='d-flex align-items-center'>{message}</div>
          </>
        )
      },
    },
    {
      dataField: "Action",
      text: "Action",
      style: { width: "10px" },
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div className='d-flex gap-3'>
              <Link
                to={`/job/view-job-history?job_id=${row?.jobId}&history_id=${row?.id}`}
                title='View Platform'
                className='btn btn-icon btn-light-primary btn-sm'
              >
                <span className='svg-icon svg-icon-md svg-icon-primary'>
                  <FaEye />
                </span>
              </Link>
            </div>
          </>
        )
      },
    },
  ]

  const fetchJobHistory = () => {
    setLoading(true)
    setHistoryData([])
    if (!job_id) {
      errorToast("Backup ID is not found")
      navigate("/job/list")
    }
    getJobHistory(job_id, {
      sortOrder: "desc",
      limit: pageSize,
      skip,
      status: historyStatus,
    })
      .then((res: any) => {
        setHistoryData(res?.data)
        setTotalCount(res?.metadata?.count)
        setLoading(false)
      })
      .catch((err: any) => {
        errorToast(err?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    setCurrentPage(1)
    setSkip(0)
  }, [pageSize, historyStatus])

  useEffect(() => {
    clearTimeout(timer)
    const newTimer: any = setTimeout(() => {
      fetchJobHistory()
    }, 100)
    setTimer(newTimer)
  }, [skip, historyStatus, pageSize])

  useEffect(() => {
    setCreditLoading(true)
    getJobCredits({ activeOnly: true, jobId: job_id })
      .then(async (res: any) => {
        let creditAmount = 0,
          usedCredits = 0
        await res?.data?.forEach((v: any) => {
          creditAmount += v?.creditAmount
          usedCredits += v?.usedCredits
        })
        setCredits({
          remainingCredits: creditAmount - usedCredits,
          usedCredits,
          totalCredits: creditAmount,
        })
        setCreditLoading(false)
      })
      .catch((err: any) => {
        setCreditLoading(false)
        errorToast(err?.message)
      })
  }, [])

  return (
    <>
      <div className='d-flex gap-5 mb-5'>
        <div className='card p-5 w-200px'>
          <h4>Total Credits</h4>
          <h4 className='text-muted mb-0'>
            {creditLoading ? <LoaderSpinner /> : credits?.totalCredits}
          </h4>
        </div>
        <div className='card p-5 w-200px'>
          <h4>Used Credits</h4>
          <h4 className='text-muted mb-0'>
            {creditLoading ? <LoaderSpinner /> : credits?.usedCredits}
          </h4>
        </div>
        <div className='card p-5 w-200px'>
          <h4>Remaining Credits</h4>
          <h4 className='text-muted mb-0'>
            {creditLoading ? <LoaderSpinner /> : credits?.remainingCredits}
          </h4>
        </div>
      </div>
      <div className='card p-5'>
        <div className='filters d-flex mb-3 gap-5'>
          <div>
            <label className='fw-semibold mb-1'>Count</label>
            <PageSizeCustom setPageSize={setPageSize} />
          </div>
          <div>
            <label className='fw-semibold mb-1'>Backup Status</label>
            <HistoryStatusCustom setHistoryStatus={setHistoryStatus} />
          </div>
        </div>
        <BootstrapTable
          wrapperClasses='table-responsive'
          bordered={false}
          classes='table table-vertical-center overflow-hidden'
          bootstrap4
          keyField='id'
          data={historyData || []}
          columns={columns}
          noDataIndication={() => (loading ? <TableLoader /> : "No Records Found")}
          rowClasses={"border-bottom"}
        />
        <div className='text-end mt-3'>
          <PaginationCustom
            total={totalCount}
            pageSize={pageSize}
            setSkip={setSkip}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  )
}
